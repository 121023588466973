.full-width-section {
    background-color: #ffffff; /* Dark background color */
    color: #000000; /* White text color */
  }
  
  .full-width-section h2 {
    color: #E40016; /* Accent color for the title */
  }
  
  .full-width-section p {
    font-size: 1.2rem; /* Slightly larger font for readability */
    max-width: 800px; /* Limit width for readability */
    margin: 0 auto; /* Center align paragraph */
  }
  
  .whatSet-pragraph{
    color: #000000;
  }