.mission-section {
    background-color: #f9fafb; /* Light background for contrast */
  }
  
  .mission-section h2 {
    font-weight: 700;
  }
  
  .mission-section p {
    font-size: 1.2rem;
  }

  .text-identity{
    color: #E40016;
  }
  