.LogoHandle {
  font-weight: 700;
  font-size: 21px;
}

.navbar-brand:hover {
  color: rgb(255 255 255) !important;
}

.navbarSetting {
  transition: background-color 0.3s;
}

.navbar {
  transition: background-color 0.3s ease;
}

.navbar.bg-transparent {
  background-color: transparent !important;
}

.navbar.bg-white {
  background-color: #ffffff !important;
}

.navbar .navbar-brand {
  color: #fff;
  font-size: 1.5rem;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.1rem;
  margin: 0 10px;
  font-weight: 600;
}

.logo-div {
  text-decoration: none;
}

.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover {
  color: #E40016;
}

.navbar-scrolled {
  background-color: white !important;
}

.nav-link-black {
  color: black !important;
}

.nav-link-black:hover {
  color: #333 !important;
}

.nav-dropdown-black {
  color: black !important;
}

.image-setting {
  height: 50px;
  width: 70px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #E40016 !important;
}

.navbar-nav .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #333;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
}

.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-nav .dropdown-item {
  font-size: 0.9rem;
  padding: 8px 15px;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-nav .dropdown-item:hover {
  background-color: #E40016;
  color: #fff;
}

.right-sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
  background-color: #fff;
  color: #333;
  transition: right 0.3s ease;
  padding: 20px;
  z-index: 1000;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1111;
}

.right-sidebar.open {
  right: 0;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}

.sidebar-content h2 {
  font-size: 1.6rem;
  color: #333;
}

.sidebar-content input,
.sidebar-content textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  font-size: 1rem;
  background-color: #f9f9f9;
  color: #333;
  transition: border-color 0.3s ease;
  margin: 6px 0;
}

.sidebar-content input:focus,
.sidebar-content textarea:focus {
  border-color: #E40016;
  outline: none;
}

.sidebar-content textarea {
  resize: none;
  height: 100px;
}

.sidebar-content button {
  padding: 12px;
  border: none;
  background-color: #E40016;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
  width: 100%;
}

.sidebar-content button:hover {
  background-color: #E40016;
}

.close-btn {
  background: none;
  border: none;
  color: #333;
  font-size: 30px;
  cursor: pointer;
  align-self: flex-end;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .right-sidebar {
    width: 100%;
  }
  .sidebar-content input,
  .sidebar-content textarea {
    font-size: 0.9rem;
  }
  .sidebar-content button {
    font-size: 1rem;
  }
  .close-btn {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  .navbarSetting {
    position: relative !important;
  }
  .navbar .navbar-nav .nav-link,
  .LogoHandle {
    color: black !important;
  }
}

@media (max-width: 400px) {
  .navbar-nav .dropdown-item {
    font-size: 0.7rem;
  }
}


@media (max-width: 992px) {
  .productsBlack {
    color: black !important;
  }
}
