
.container {
  padding: 2rem;
  font-family: "Arial", sans-serif;
  animation: fadeIn 1.5s ease-in-out;
}

.title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #E40016;
  animation: fadeIn 1s ease-in;
}

.section {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.section:hover {
  transform: translateY(-5px);
}

.heading {
  font-size: 1.75rem;
  color: black;
  margin-bottom: 1rem;
  animation: fadeInUp 1s ease-in-out;
}

.paragraph {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  animation: fadeInUp 1s ease-in-out 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.studentWellbeingHeader {
  background: url('../assets/interactivitySolution.webp') center center / cover;
  position: relative;
  color: #ffffff; 
}

.studentWellbeingHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.studentWellbeingHeader .container {
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.textJustify{
  text-align: justify;
}

.Margin-TopAllService{
  margin-top: 1.5rem;
}

.InteractiveSubSections {
  background-color: #f9fafb; /* Light background for contrast */
}

.InteractiveSubSections h2 {
  font-weight: 700;
}

.InteractiveSubSections p {
  font-size: 1.2rem;
  text-align: justify;
}

.textMission{
  color: #E40016;
}


@media(max-width: 992px){
  .Margin-TopAllService{
    margin-top: 0;
  }
}

@media(max-width:768px){
  .studentWellbeingHeader .container {
    padding: 1rem;
  }
}