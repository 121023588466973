  /* Apply global styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  color: #333;
}

/* Container styling */
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

/* Page title styling */
.customHeading {
  font-family: 'Poppins', sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  color: #E40016;
  margin-bottom: 1.5rem;
}

/* Paragraph styling */
.textCenter {
  font-size: 1.1rem;
  color: #555;
}

/* Card styling */
.shadowSm {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shadowSm:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.cardBody{
  padding: 30px !important;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
}

.cardTitle {
  font-size: 1.5rem;
  color: #E40016;
  font-weight: bold;
}

.cardText {
  font-size: 1rem;
  color: #444;
  text-align: justify;
}

.cardTextIcon{
  font-size: 1rem;
  color: #444;
}

/* Button styling */
.btnPrimary {
  background-color: #E40016;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.3s ease;
}

.btnPrimary:hover {
  background-color: #dd2627;
}

/* List styling */
ol{
  text-align: justify;
}
ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #333;
}

ul li {
  margin-bottom: 0.5rem;
}

.ResponsiveCards{
  height: 360px;
}

/* Responsive spacing adjustments */
@media (max-width: 768px) {
  .customHeading {
    font-size: 2.2rem;
  }

  .cardTitle {
    font-size: 1.25rem;
  }

  .cardText, .textCenter {
    font-size: 1rem;
  }

  .btnPrimary {
    padding: 0.4rem 1rem;
  }
}

@media (max-width: 1200px) {
  .ResponsiveCards{
    height: 100%;
  }
}



.studentWellbeingHeader {
  background: url('../assets/LearningTeaching.webp') center center / cover;
  position: relative;
  color: #ffffff; /* White text color */
}

.studentWellbeingHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  z-index: 1;
}

.studentWellbeingHeader .container {
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.textJustify{
  text-align: justify;
}

.Margin-TopAllService{
  margin-top: 1.5rem;
}

@media(max-width: 992px){
  .Margin-TopAllService{
    margin-top: 0;
  }
}

@media(max-width:768px){
  .studentWellbeingHeader .container {
    padding: 1rem;
  }
}