  /* Overall Container */
.contact-container {
    padding: 6rem 2rem 4rem;
    background: linear-gradient(135deg, #f8f9fa, #ffffff);
  }
  
  .contact-container h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #E40016;
    font-weight: bold;
  }
  
  /* Contact Content Layout */
  .contact-content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Image Section */
  .contact-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .contact-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .contact-image img:hover {
    transform: scale(1.05);
  }
  
  /* Form Section */
  .contact-form-container {
    flex: 1;
    background: #ffffff;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.9rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .contact-form textarea {
    resize: none;
    min-height: 120px;
  }
  
  .contact-form button {
    padding: 0.9rem;
    background: #e40016;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .contact-form button:hover {
    background: #bb0012;
    transform: translateY(-3px);
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .contact-content {
      flex-direction: column;
    }
  
    .contact-image {
      height: 250px;
    }
    .contact-container {
        padding: 2rem 2rem 4rem;
      }
  }
  
  @media (max-width: 768px) {
    .contact-form {
      max-width: 100%;
    }
  }
  
  