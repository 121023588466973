.text-WebsiteColor {
  color: #e40016;
}
.student-wellbeing-header {
  background: url("../assets/StudentsWellBeing.webp") center center / cover;
  position: relative;
  color: #ffffff; /* White text color */
}

.student-wellbeing-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  z-index: 1;
}

.student-wellbeing-header .container {
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.textJustify{
  text-align: justify;
}


@media(max-width:768px){
  .student-wellbeing-header .container {
    padding: 1rem;
  }
}

.Margin-TopAllService{
  margin-top: 1.5rem;
}

@media(max-width: 992px){
  .Margin-TopAllService{
    margin-top: 0;
  }
}