.section {
    background-color: #f1f3f5; /* Subtle light background color */
  }
  
  .description {
    color: #6c757d; /* Muted color for readability */
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .image {
    max-width: 300px; /* Restrict image width */
    border-radius: 8px;
  }
  
  .countingSection {
    background-color: #f8f9fa; /* Light background color */
  }
  
  .countColumn {
    padding: 20px;
    border-right: 1px solid #dee2e6; /* Right border for columns */
  }
  
  .countColumn:last-child {
    border-right: none; /* Remove border from the last column */
  }
  .customcolor{
    color : #E40016;
  }
  .whyChooseSection {
    background-color: #f8f9fb; /* Background color for contrast */
    color: gray; 
    font-size: 30px;
  }