.container {
  font-family: "Arial", sans-serif;
  animation: fadeIn 1s ease-in-out;
  padding: 3rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #E40016;
  animation: fadeIn 1s ease-in;
}

.title::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background-color: #E40016;
  margin: 0.5rem auto;
  border-radius: 2px;
}

.description {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.servicesSection {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.sectionTitle {
  font-size: 1.8rem;
  color: #E40016;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.card {
  background-color: #e8f4ff;
  color: #000000;
  padding: 1rem;
  border-radius: 8px;
  width: 45%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  animation: fadeUp 0.6s ease forwards;
  opacity: 0;
  transform: translateY(20px);
}

.card:nth-child(odd) {
  animation-delay: 0.1s;
}
.card:nth-child(even) {
  animation-delay: 0.2s;
}

@keyframes fadeUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 115, 230, 0.15);
  background-color: #d0e9ff;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }

  .title {
    font-size: 1.8rem;
  }

  .sectionTitle,
  .description {
    font-size: 1.1rem;
  }
}


.studentWellbeingHeader {
  background: url('../assets/NetworkingCabling.webp') center center / cover;
  position: relative;
  color: #ffffff; /* White text color */
}

.studentWellbeingHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  z-index: 1;
}

.studentWellbeingHeader .container {
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.textJustify{
  text-align: justify;
}

.Margin-TopAllService{
  margin-top: 1.5rem;
}

@media(max-width: 992px){
  .Margin-TopAllService{
    margin-top: 0;
  }
}

@media(max-width:768px){
  .studentWellbeingHeader .container {
    padding: 1rem;
  }
}