/* About Section */
.about-section {
    padding: 50px 0;
  }
  
  .about-section h2 {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #E40016;
  }
  
  .about-section p {
    font-size: 1.1rem;
    text-align: justify;
  }
  
  .about-section img {
    height: 300px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }

  @media (max-width: 550px) {
    .about-section img {
      height: 250px;
    }
    .about-section {
      padding: 50px 20px;
  }
  }  