/* .hero-section {
  position: relative;
  width: 100%;
}

.hero-heading {
  padding: 12%;
  font-family: "Anton", sans-serif;
}

.carousel-item .d-block {
  width: 100%;
  height: 100%;
}

.carousel-item-1 {
  background-image: url('../assets/slider1.webp'); 
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  transform: scale(0.8); 
  transition: transform 3s ease, opacity 1s ease; 
}

.carousel-item-2 {
  background-image: url('../assets/slider2.webp'); 
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  transform: scale(0.8);
  transition: transform 3s ease, opacity 1s ease;
}

.carousel-item-3 {
  background-image: url('../assets/immersive.webp'); 
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  transform: scale(0.8);
  transition: transform 3s ease, opacity 1s ease;
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero-content h1 {
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-size: 3rem;
  text-align: center;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  opacity: 0;
  transition: opacity 0.6s ease, transform 3s ease;
}

.carousel-item {
  height: 100vh;
  position: relative;
  transform: scale(0.8);
  transition: transform 3s ease, opacity 0.2s ease;
}

.carousel-item-next,
.carousel-item-prev {
  transform: scale(0.8); 
  opacity: 0.8;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  transform: scale(0.8);
  opacity: 0.8;
}

.carousel-item-1::before,
.carousel-item-2::before,
.carousel-item-3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1;
}

.hero-heading {
  position: relative;
  z-index: 2;
  padding: 36% 30% 12% 8%;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-size: 3rem;
  text-align: center;
}

@media (max-width: 576px) {
  .hero-heading {
    font-size: 1.8rem;
  }
}

@media(max-width:992px) {
  .hero-heading {
    padding: 12%;
  }
} */





/* 
.hero-section {
  position: relative;
  width: 100%;
}

.hero-heading{
  padding: 12%;
  font-family: "Anton", sans-serif;
}


.carousel-item .d-block {
  width: 100%;
  height: 100%;
}

.carousel-item-1 {
  background-image: url('../assets/slider1.jpg');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.carousel-item-2 {
  background-image: url('../assets/slider2.jpg');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.carousel-item-3 {
  background-image: url('../assets/slider3.jpg');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero-content h1 {
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-size: 3rem;
  text-align: center;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.hero-section {
position: relative;
width: 100%;
}

.hero-content {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
}

.hero-heading {
position: relative;
z-index: 2;
padding: 36% 30% 12% 8%;
color: white;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
font-size: 3rem;
text-align: center;
}

.carousel-item {
height: 100vh;
position: relative;
transition: transform 3s ease, opacity 0.2s ease; 
}


.carousel-item-next,
.carousel-item-prev {
transform: scale(1.2); 
opacity: 0.8; 
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
transform: scale(1.2); 
opacity: 0.8;
}

.carousel-item-1,
.carousel-item-2,
.carousel-item-3 {
position: relative;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
transition: transform 3s ease, opacity 1s ease;
}

.carousel-item-1::before,
.carousel-item-2::before,
.carousel-item-3::before {
content: "";
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5); 
z-index: 1;
}

.carousel-item-1 {
background-image: url('../assets/slider1.webp');
}
.carousel-item-2 {
background-image: url('../assets/slider2.webp');
}
.carousel-item-3 {
background-image: url('../assets/immersive.webp');
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
opacity: 0;
transition: opacity 0.6s ease, transform 3s ease;
}

@media (max-width: 576px) {
.hero-heading {
  font-size: 1.8rem;
}
}

@media(max-width:992px){
.hero-heading{
   padding: 12%;
}
} */

.hero-section {
  position: relative;
  width: 100%;
}

.hero-heading {
  padding: 12% 12% 0 0;
  font-family: "Anton", sans-serif;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-size: 3rem;
  text-align: center;
  z-index: 111;
  position: absolute;
  bottom: 20%;
  right: 20%;
}

.carousel-item {
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel-item-1 {
  background-image: url('../assets/slider1.webp');
}

.carousel-item-2 {
  background-image: url('../assets/slider2.webp');
}

.carousel-item-3 {
  background-image: url('../assets/immersive.webp');
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay for better text visibility */
  z-index: 1;
}

.carousel-inner {
  height: 100%;
}

.d-block.w-100 {
  height: 100%;
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  transform: translateX(0);
  opacity: 1;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  transition: transform 0.6s ease-in-out, opacity 0.6s ease;
}

@media (max-width: 992px) {
  .hero-heading {
    padding: 12%;
    bottom: 0;
    right: 0;
    position: relative;
    text-align: center;
    font-size: 1.8rem;
  }
}


