.container {
  padding: 3rem;
  font-family: Arial, sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.FeatureSectionContainer{
  padding: 3rem;
  font-family: Arial, sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.pageTitle {
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #E40016;
  animation: fadeIn 1s ease-in;
}

.sectionTitle {
  font-size: 2rem;
  color: #E40016;
  text-align: center;
  font-weight: bold;
  position: relative;
  animation: slideIn 1s ease-in-out;
}

.sectionDescription {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  opacity: 0.9;
  animation: fadeIn 1s ease-in-out;
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for larger screens */
  gap: 1rem;
  margin-top: 2rem;
}

.featureCard {
  background-color: #eef7ff;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.featureCard:hover {
  transform: translateY(-5px);
  background-color: #d4e9ff;
}

.featureIcon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #E40016;
}

.featureText {
  font-size: 1.1rem;
  color: #333;
  text-align: center;
  line-height: 1.5;
}

@media (max-width: 992px) {
  .featuresGrid {
    grid-template-columns: 1fr 1fr; /* Two columns on medium screens */
  }
}

@media (max-width: 768px) {
  .featuresGrid {
    grid-template-columns: 1fr; /* Single column on small screens */
  }

  .sectionTitle {
    font-size: 1.8rem;
  }
}

.studentWellbeingHeader {
  background: url('../assets/MobileDevice.webp') center center / cover;
  position: relative;
  color: #ffffff; /* White text color */
}

.studentWellbeingHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  z-index: 1;
}

.studentWellbeingHeader .container {
  position: relative;
  z-index: 2;
  padding: 5rem;
}

.textJustify{
  text-align: justify;
}

.Margin-TopAllService {
  margin-top: 1.5rem;
}

@media (max-width: 992px) {
  .Margin-TopAllService {
    margin-top: 0;
  }
  .FeatureSectionContainer{
    padding: 0 3rem;
  }
}

@media (max-width: 768px) {
  .studentWellbeingHeader .container {
    padding: 1rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes growLine {
  from {
    width: 0;
  }
  to {
    width: 50px;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.slideIn {
  animation: slideIn 1s ease-in-out;
}


.InteractiveSubSections {
  background-color: #f9fafb; /* Light background for contrast */
}

.InteractiveSubSections h2 {
  font-weight: 700;
}

.InteractiveSubSections p {
  font-size: 1.2rem;
  text-align: justify;
}

.textHeadings{
  color: #E40016;
}