/* About Section */
.aboutSection {
    padding: 50px 0;
  }
  
  .aboutSection h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #E40016;
  }
  
  .aboutSection p {
    font-size: 1.2rem;
    text-align: justify;
  }
  
  .aboutSection img {
    height: 400px;
    width: 100%;
    border-radius: 20px;
  }

  @media (max-width: 550px) {
    .aboutSection img {
      height: 350px;
    }
    .aboutSection {
      padding: 50px 20px;
  }
  }  

  .CustomHeading{
    font-family: 'Poppins', sans-serif;
    font-size: 2.8rem;
    font-weight: 600;
    color: #E40016;
  }


  .studentWellbeingHeader {
    background: url('../assets/ChargingStorageSolution.webp') center center / cover;
    position: relative;
    color: #ffffff; /* White text color */
  }
  
  .studentWellbeingHeader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
    z-index: 1;
  }
  
  .studentWellbeingHeader .container {
    position: relative;
    z-index: 2;
    padding: 5rem;
  }

  .textJustify{
    text-align: justify;
  }

  .Margin-TopAllService{
    margin-top: 1.5rem;
  }

  .SubText{
    color: rgba(33, 37, 41, 0.75);
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: 2rem;
  }

  @media(max-width: 992px){
    .Margin-TopAllService{
      margin-top: 0;
    }
  }

  @media(max-width:768px){
    .studentWellbeingHeader .container {
      padding: 1rem;
    }
  }