.immersiveLearningSection {
    border-radius: 15px;
  }
  
  .title {
    font-size: 3rem;
    font-weight: 700;
    color: #2d3748;
    letter-spacing: 1px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .description {
    font-size: 1.2rem;
    color: #4a5568;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }
  
    .description {
      font-size: 1rem;
    }
  }
  
  .studentWellbeingHeader {
    background: url('../assets/immersive.webp') center center / cover;
    position: relative;
    color: #ffffff; 
  }
  
  .studentWellbeingHeader::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }
  
  .studentWellbeingHeader .container {
    position: relative;
    z-index: 2;
    padding: 5rem;
  }

  .textJustify{
    text-align: justify;
  }

  .Margin-TopAllService{
    margin-top: 1.5rem;
  }

  @media(max-width: 992px){
    .Margin-TopAllService{
      margin-top: 0;
    }
  }

  @media(max-width:768px){
    .studentWellbeingHeader .container {
      padding: 1rem;
    }
  }

.card {
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid black;
  height: 475px;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); 
}

.img {
  height: 200px;
  object-fit: cover;
}

.cardTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #e40016;
  margin-bottom: 15px;
}

.cardText {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.container {
  margin-top: 50px;
}

.row {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .col {
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .card {
    height: 100%;
  }
}


  