.App {
  text-align: center;
}

.PaddingForApp{
  padding-top: 3rem;
}

@media(max-width: 992px){
  .PaddingForApp{
    padding-top: 0;
  }
}